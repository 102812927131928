selectWeekDays = function (selector, monthIdentifier, dayId) {
  dayId = dayId !== 0 ? dayId - 1 : 6;

  $(`input[day-id='${dayId}'][month-identifier=${monthIdentifier}]`).each(function () {
    if (!$(this).prop('disabled')) {
      $(this).prop('checked', selector.checked);
    }
  });

  setDates(monthIdentifier);
}

selectWeek = function (selector, monthIdentifier, weekId) {
  $(`input[week-id='${weekId}'][month-identifier=${monthIdentifier}]`).each(function () {
    if (!$(this).prop('disabled')) {
      $(this).prop('checked', selector.checked);
    }
  });

  setDates(monthIdentifier);
}

selectAllDates = function (selector, monthIdentifier) {
  $(`input[availability-selector=true][month-identifier=${monthIdentifier}]`).each(function () {
    if (!$(this).prop('disabled')) {
      $(this).prop('checked', selector.checked);
    }
  });

  setDates(monthIdentifier);
}

selectDateTimes = function (selector, monthIdentifier, weekId, dayId) {
  var identifier = monthIdentifier.slice(0, -8)

  if (window[`isHourly${identifier}`]) {
    $(`input[month-identifier=${monthIdentifier}][week-id='${weekId}'][day-id='${dayId}']`).each(function () {
      if (!$(this).prop('disabled')) {
        $(this).prop('checked', selector.checked);
      }
    });
  }

  setDates(monthIdentifier)
}

setDates = function (monthIdentifier) {
  var identifier = monthIdentifier.slice(0, -8)
  var element = $(`.availability-input[identifier=${identifier}]`);
  var dates = [];

  if (window[`isHourly${identifier}`]) {
    $(`input[availability-selector=true][identifier=${identifier}]`).each((key, value) => {
      if (value.checked && value.getAttribute('shift-time')) {
        dates.push(`${value.getAttribute('date')};${value.getAttribute('shift-time')}`);
      }
    });
  } else {
    $(`input[availability-selector=true][identifier=${identifier}]`).each((key, value) => {
      if (value.checked) {
        dates.push(value.getAttribute('date'));
      }
    });
  }

  element.val(dates);
}
