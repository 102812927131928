orderItemComments = function(path) {
    $("body").addClass("loading");
    $.get(path)
        .done(function (data) {
            modal(data, I18n.t('js.event_orders.comments'), "", I18n.t('js.event_orders.close'));
            $("body").removeClass("loading");
        });
}

editOrderItem = function(path){
    $('body').css('cursor', 'progress');
    $.get(path)
        .done(function (data) {
            $('body').css('cursor', 'default');
            $("body").removeClass("loading");
            modal(
                data,
                I18n.t('js.event_orders.save_article'),
                '<span class=\'fas fa-save mr-1 fa-fw\'></span>' + I18n.t('js.event_orders.save'),
                '<span class=\'fas fa-window-close mr-1 fa-fw\'></span>' +  I18n.t('js.event_orders.close'),
                '<span class=\'fas fa-trash mr-1 fa-fw\'></span>' +  I18n.t('js.event_orders.delete'));
        });
}
